import { Box, useTheme } from '@mui/material';
import { ContentCard } from '../../components/ContentCard/ContentCard';
import { Copy } from '../../copy/Copy';
import { dynamicTextColor } from '../../helpers/dynamicTextColor';
import { Text } from '../../components/Text/Text';
import { Button } from '../../components/Button/Button';
import { buttonSx } from '../../styles/Global.style';
import { BURNS } from '../../constants/links';

export const Events = () => {
  const theme = useTheme();
  const quaternary = theme.palette.quaternary.main;

  return (
    <>
      <ContentCard
        title={Copy.generate('Events.intro.title')}
        heading={Copy.inject('Events.intro.heading', {
          book: dynamicTextColor('book your spot today.', quaternary),
        })}
        content={Copy.generate('Events.intro.content')}
        isColumn
        isTop
      />

      <ContentCard
        img="/assets/img/burns.webp"
        title={Copy.generate('Events.burns.title')}
        heading={Copy.inject('Events.burns.heading', {
          night: dynamicTextColor('Night.', theme.palette.quaternary.main),
        })}
        content={Copy.generate('Events.burns.content')}
        isSecondaryCard
        isReversed
      >
        <Box>
          <Button
            text={Copy.generate('Buttons.book')}
            onClick={() => window.open(BURNS)}
            sx={buttonSx(theme)}
          />
        </Box>
      </ContentCard>

      <ContentCard
        img="/assets/img/xmas.webp"
        title={Copy.generate('Events.xmas.title')}
        heading={Copy.inject('Events.xmas.heading', {
          fayre: dynamicTextColor('Fayre.', theme.palette.quaternary.main),
        })}
        content={Copy.generate('Events.xmas.content')}
      >
        <Box>
          <Text
            text={Copy.generate('Events.eventPassed')}
            color={theme.palette.tertiary.main}
          />
        </Box>
      </ContentCard>

      <ContentCard
        img="/assets/img/halloween.webp"
        title={Copy.generate('Events.halloween.title')}
        heading={Copy.inject('Events.halloween.heading', {
          disco: dynamicTextColor(`Disco.`, theme.palette.quaternary.main),
        })}
        content={Copy.generate('Events.halloween.content')}
        isSecondaryCard
        isReversed
      >
        <Box>
          <Text
            text={Copy.generate('Events.eventPassed')}
            color={theme.palette.tertiary.main}
          />
        </Box>
      </ContentCard>

      <ContentCard
        img="/assets/img/chippy.webp"
        title={Copy.generate('Events.chippie.title')}
        heading={Copy.inject('Events.chippie.heading', {
          chippie: dynamicTextColor(
            `Chippy Hike.`,
            theme.palette.quaternary.main,
          ),
        })}
        content={Copy.generate('Events.chippie.content')}
      >
        <Box>
          <Text
            text={Copy.generate('Events.eventPassed')}
            color={theme.palette.tertiary.main}
          />
        </Box>
      </ContentCard>
    </>
  );
};

export const SCHOOL_LINK =
  'http://www.baljaffray.e-dunbarton.sch.uk/get-in-touch/';

export const EASYFUNDRAISING =
  'https://www.easyfundraising.org.uk/causes/baljaffrayprimary/';

export const LOTTERY =
  'https://www.yourschoollottery.co.uk/lottery/school/baljaffray-primary-school';

export const HALLOWEEN =
  'https://www.tickettailor.com/events/baljaffraypta/1422507';

export const PAYPAL =
  'https://www.paypal.com/donate/?hosted_button_id=D5FYPY5TALA2U';

export const XMAS_INDIVIDUAL =
  'https://www.tickettailor.com/events/baljaffraypta/1468167';

export const XMAS_FAMILY =
  'https://www.tickettailor.com/events/baljaffraypta/1468208';

export const XMAS_HAMPER =
  'https://www.tickettailor.com/events/baljaffraypta/1468228';

export const BURNS =
  'https://www.tickettailor.com/events/baljaffraypta/1527963';
